import { apiUrl } from "@/_services/config";

export const categoriesService = {
    getCategoriesTree,
    getFirstCategories
};

async function getFirstCategories() {
    const response = await fetch(`${apiUrl}api/v1/getCategoriesTreeLevel2`)

    const data = await response.json();
    return data;
}

async function getCategoriesTree(name, level) {

    const response = await fetch(`${apiUrl}api/v1/getCategoriesTreeLevel${level}`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({category:name})
    })

    const data = await response.json();

    return data;

}
