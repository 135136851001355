<template>
  <v-app>

    <Layout/>

    <v-main>

      <v-img
          max-height="150"
          max-width="250"
          :src="require(`@/assets/best-price.png`)"
          style=" position: absolute; right: 0; top: 80px; opacity: 80%; background-color: #fff; z-index: 0"
          class="d-none d-md-flex d-lg-flex"
      ></v-img>

      <template>
        <v-sheet
            class="d-flex justify-space-around mt-3"
        >
          <v-slide-group
              multiple
              show-arrows
          >
            <a :href="'/files/Listovka_Narodnaya_Vypusk_10_2021.pdf'" download style="text-decoration: none;">
              <div class="my-2 mx-4">
                <v-btn
                    :color="$root.brownThemeColor"
                    dark
                >
                  Листовка в формате PDF
                </v-btn>
              </div>
            </a>

            <a :href="'/files/KATALOG_NARODNYJ_01-2021.pdf'" download style="text-decoration: none;">
              <div class="my-2 mx-4">
              <v-btn
                  :color="$root.brownThemeColor"
                  dark
              >
                Каталог в формате PDF
              </v-btn>
            </div>
            </a>

            <a :href="'/files/KATALOG_NARODNYJ_01-2021_SVETOTEKHNIKA.pdf'" download style="text-decoration: none;">
             <div class="my-2 mx-4">
              <v-btn
                  :color="$root.brownThemeColor"
                  dark
              >
                Каталог в формате PDF
                Светотехника
              </v-btn>
            </div>
            </a>

            <a :href="'/files/KATALOG_NARODNYJ_01-2021_ELEKTROTEKHNIKA_I_KPP.pdf'" download style="text-decoration: none;">
              <div class="my-2 mx-4">
              <v-btn
                  :color="$root.brownThemeColor"
                  dark
              >
                Каталог в формате PDF
                Электротехника и КПП
              </v-btn>
            </div>
            </a>

          </v-slide-group>
        </v-sheet>
      </template>





      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
  import Layout from '@/components/Layout'
  export default {
    components: {
      Layout
    },
    beforeCreate() {
      this.$store.dispatch('getMainPageID')
    },
  }
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
