import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/WelcomePage.vue'),
    name: 'main'
  },
  {
    path: '/category/:id',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/ProductsList.vue'),
    name: 'category'
  },
  {
    path: '/product/:id',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/ProductDetails.vue'),
    name: 'product'
  },
  {
    path: '/search',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/SearchPage.vue'),
    props: (route) => ({query: route.query.q}),
    name: 'search'
  },
  {
    path: '/certificates',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/CertificatesPage.vue'),
    name: 'certificates'
  },
  { path: "*",  redirect: '/'}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
