const state = {
    mainPageID:''
}

const mutations = {
    'UPDATE_MAIN_PAGE_ID' (state, categoriesList) {
        state.mainPageID = categoriesList;
    }
}

const actions = {
    async getMainPageID({commit}) {
        let response = await fetch('https://narodnaya.tdme.ru:5001/api/v1/getSvet');
        let data = await response.json();
        commit('UPDATE_MAIN_PAGE_ID', data);
    }
}

const getters = {
    categories: (state) => {
        return state.mainPageID;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}