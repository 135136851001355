<template>
  <div>
        <v-navigation-drawer
            v-model="drawer"
            :clipped="$vuetify.breakpoint.lgAndUp"
            app
            width="300px"
        >

          <template>
            <v-treeview
                :items="categories"
                :load-children="fetchData"
                return-object
                open-on-click
                ref="tree"
                class="text-left"
            >
              <template v-slot:label="{ item }">

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">

                        <router-link
                            v-if="!item.children && item.to" :to="{ name: 'category', params: { id: item.id } }"
                            custom
                            v-slot="{ href, navigate }"
                        >
                          <a :href="href" @click="navigate" v-bind="attrs"
                             v-on="on" class="product-header" >{{ item.catName }}</a>
                        </router-link>
                        <span v-else
                              v-bind="attrs"
                              v-on="on"
                              style="font-weight: 500">{{ item.catName }}</span>

                      </template>
                      <span>{{ item.catName }}</span>
                    </v-tooltip>
              </template>
            </v-treeview>

            <v-list flat>
              <v-list-item-group
              >
                <v-list-item class="text-left"
                >
                  <router-link
                      :to="{ name: 'certificates' }"
                      custom
                      v-slot="{ href, navigate }"
                  >
                    <a :href="href" @click="navigate" class="text-left" style="color: #fb8c00; text-decoration: none; font-weight: bolder;" >Подбор сертификата</a>
                  </router-link>
                </v-list-item>
              </v-list-item-group>
            </v-list>

<!--            <li>-->
<!--              <router-link-->
<!--                  to="/"-->
<!--                  custom-->
<!--                  v-slot="{ href, navigate }"-->
<!--              >-->
<!--                <a :href="href" @click="navigate" class="product-header text-left" >Подбор сертификата</a>-->
<!--              </router-link>-->
<!--            </li>-->


          </template>


            <v-img
              max-height="auto"
              max-width="250"
              :src="require(`@/assets/matreshka.png`)"
              style=" position: absolute; right: 50%; bottom: 0px; opacity: 25% ; background-color: #fff; z-index: -9999"
            ></v-img>

        </v-navigation-drawer>


        <v-app-bar
            color="white"
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            app
            height="100"
        >
          <v-app-bar-nav-icon
              class="d-lg-none d-xl-none"
              @click.stop="drawer = !drawer"
              color="black"></v-app-bar-nav-icon>
          <v-toolbar-title
              style="max-width: 500px"
              class="ml-0 pl-4"
          >

            <router-link to="/" >
              <v-img
                  class="mx-2 hidden-xs-only"
                  :src="require(`@/assets/Лого_01_01.png`)"
                  width="auto"
              ></v-img>
            </router-link>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              flat
              dark
              background-color="white"
              solo-inverted
              hide-details
              label="Поиск по каталогу"
              class="search-input"
              :append-icon="'mdi-magnify'"
              @click:append="doSearch"
              v-on:keyup.enter="doSearch"
          ></v-text-field>
          <v-spacer></v-spacer>

          <div>
            <div class="float-left">
              <a href="tel:88007006326" class="mx-2" style="color: black; text-decoration: none;">
              <v-icon
                  medium
                  color="black"
                  class="my-2"
              >
                mdi-phone
              </v-icon>
                <span style="text-decoration: underline" class="hidden-sm-and-down">8 (800) 700-63-26</span></a>
            </div>
            <v-spacer></v-spacer>
            <div class="float-left">
              <a href="mailto:info@necm.ru" class="mx-2" style="color: black; text-decoration: none;">
              <v-icon
                  medium
                  color="black"
              >
                mdi-email
              </v-icon>
                <span style="text-decoration: underline" class="hidden-sm-and-down">info@necm.ru</span></a>
            </div>

          </div>

          <v-spacer></v-spacer>
        </v-app-bar>
  </div>
</template>

<script>

import { categoriesService } from "@/_services/getCategoriesTree";

export default {
  name: "Layout.vue",
  data() {
    return {
      drawer: null,
      active: [],
      open: [],
      search: '',
      categories: []
    }
  },

  async created() {
    this.categories = await categoriesService.getFirstCategories()
  },

  methods: {
    doSearch(){
      if( this.$route.query.q != this.search && this.search.length > 3){
        this.$router.push({
          path: '/search',
          query: {
            q: this.search
          }
        })
      }

    },

    async fetchData (item) {

      const children = await categoriesService.getCategoriesTree(item.catName, item.level + 1 )

      const parentNode = this.$refs.tree.nodes[item.id]

      let childNode;
      children.forEach((child) => {
        childNode = {...parentNode, item: child, vnode: null}
        this.$refs.tree.nodes[child.id] = childNode
      })

      item.children.push(...children)

      },
    },
}
</script>

<style>
.search-input .v-icon {
  color: #fb8c00 !important;
}

.search-input .v-icon.primary--text {
  color: #fb8c00 !important;
}

.search-input{
  background-color: white;
  border: 2px solid grey;
}
.theme--dark.v-label {
  color: grey!important;
}
.theme--dark.v-input input, .theme--dark.v-input textarea{
  color: black!important;
}

</style>
